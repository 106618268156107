.card {
    padding: 10px;
    display: flex;
    border-radius: 20px;
    background-color: #fff;
    margin: 20px 0px;
    font-family: "Open Sans", sans-serif;
}

.card__provider {
    height: 100%;
    width: 100%;
    padding: 24px 0 24px 24px;
    display: flex;
    gap: 20px;
    align-items: flex-start;
    flex: 1 1 0;
}

.card__providerHeader h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    color: #31405C;
}

.card__providerHeader p {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;
    opacity: .8;
    color: #31405C;
}

.card__providerInfo {
    margin-top: 16px;
    display: flex; 
    align-items: flex-start;
    gap: 10px;
}

.card__providerInfo p {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;
    color: #31405C;
}

.card__providerInfo span {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;
    color: #31405C; 
}

.more__span {
    cursor: pointer;
    display: inline-block;
    margin-left: 5px !important;
    font-weight: 600 !important;
}

.card__tags {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.tag {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    padding: 5px 12px;
    border-radius: 100px;
    border: 1px solid #D79FB9;
    background-color: rgba(215, 159, 185, 0.1);
}

.moreTags {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: underline;
    border-radius: 100px;
    cursor: pointer;
    /* border: 1px solid #D79FB9;
    background-color: rgba(215, 159, 185, 0.1); */
}

.card__timeslots {
    width: 480px;
    padding: 20px 0;
}

.card__timeslotWrapper {
    display: flex;
    width: 400px;
    margin: 0 auto;
    flex-wrap: nowrap;
}

.card__timeslot {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    align-items: stretch;
    font-size: 14px;
    padding: 0 3.5px;
    margin: unset;
}

.timeslot__button {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    border-radius: 6px;
    border: 1px solid rgba(125, 202, 218, 1);
    padding: 4px 3.5px;
    background-color: rgba(125, 202, 218, 0.2);
    margin-bottom: 10px;
    cursor: pointer;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.timeslot__button_line {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    border-radius: 6px;
    border: 1px solid rgba(125, 202, 218, 0);
    padding: 4px 3.5px;
    margin-bottom: 10px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.timeslot__button:hover {
    background-color: rgba(125, 202, 218, 0.5);
}

.isNotAvailable {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #31405C;
    font-weight: 600;
    height: 100%;
}

@media (max-width: 1024px) {
    .card__provider {
        flex-direction: column;
    }
}

@media (max-width: 840px) {
    .card__providerInfo p {
        font-size: 13px;
    }

    .card__providerHeader h2 {
        font-size: 18px;
    }
    
    .card__providerHeader p {
        font-size: 14px;
    }
        
  .card {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .card__timeslots {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 480px;
  }

  .timeslot__button {
    width: 100%;
    max-width: 70px;
    padding: 5px 2px;
    font-size: 11px;
  }
  .timeslot__button_line {
    width: 100%;
    max-width: 70px;
    padding: 5px 2px;
    font-size: 11px;
}
  .card__timeslotWrapper {
    width: 100%;
  };

  .card__providerInfo p {
    font-size: 13px;
    }

    .card__providerInfo span {
        font-size: 13px;
    }


    .tag {
        font-size: 13px;
    }

}