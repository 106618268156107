.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.week_view {
  position: fixed;
  width: 100%;
  top: 200px;
  z-index: 10;
  background-color: #fff;
  padding-top: 10px;
}

.desktop-providers {
  margin-top: 300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .week_view {
    top: 184px;
   }
}

@media (max-width: 840px) {
  .desktop-providers {
    margin-top: 350px;
  }
}

@media (max-width: 768px) {
  .week_view {
   top: 220px;
  }

  .desktop-providers {
    margin-top: 530px;
  }
}

@media (max-width: 600px) {
  .desktop-providers {
    margin-top: 550px;
  }
}