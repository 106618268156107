.header {
  background-color: #31405c;
  position: relative;
  padding: 30px 0;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  padding-top: 90px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.header__logo {
  position: absolute;
  top: 20px;
  left: 15px;
  width: 250px;
}

.header__logo img {
  max-width: 100%;
}


.phone {
  color: #fff;
  font-size: 13px;
  position: absolute;
  top: 35px;
  right: 20px;
}

.phone a {
  color: #fff;
}

.header__text {
  font-size: 15px;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 16px;
}


@media (max-width: 1200px) {
  .header__logo {
    width: 150px;
  }
  .header {
    padding-top: 70px;
  }
  .phone {
    top: 15px;
    font-size: 12px;
    max-width: 200px;
    text-align: right;
  }

  .header__text {
    font-size: 13px;
  }
  
}

/* @media (max-width: 768px) {
  .header {
    position: relative;
  }
} */