.view {
    background-color: #fff;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    color:#31405C;
}

@media (max-width: 768px) {
    .view {
        box-shadow: unset;
        padding: 10px;
    }
  }

.view__wrapper {
    padding-bottom: 20px;
    margin-bottom: -10px;
    
}

.info {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.infoMedia {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-align: right;
    margin-top: 20px;
    display: none; 
}

.calendar {
    min-width: 460px;
    margin-left: auto;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
}

.calendar__dayView {
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.calendar__date {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
}

.calendar__day {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.2;
}

@media (max-width: 600px) {
    .calendar {
        min-width: 300px;
        gap: 10px;
    }
    .calendar__date {
        font-size: 13px;
    }
    .calendar__day {
        font-size: 12px;
    }
    .info {
       display: none;
    }
}

@media (max-width: 840px) {
    .calendar {
        margin: 0 auto;
    }
    .info {
        display: none;
     }

    .infoMedia {
        display: block;
    }
}