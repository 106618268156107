*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  /* background-color: #31405C; */
  /* background-color: #7DCADA; */
  /* background-color: #D79FB9; */
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
